import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import {
  checkPathnamesAreEqual,
  checkPathnameStartsWith,
} from '@app/services/routeHelpers';
import {
  FilmImpression,
  getSnowplowPageType,
  trackSnowplowFilmImpression,
} from '@app/services/snowplow';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const useSnowplowFilmImpressionTracker = () => {
  const router = useRouter();

  const { httpContext, countryCode, vanityPathPageType } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      countryCode: state.user.geoLocation,
      vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
    }),
    shallowEqual,
  );

  const trackOnTodayPage = checkPathnamesAreEqual(router.pathname, '/showing');

  const trackOnFilmDetailsPage = checkPathnamesAreEqual(
    router.pathname,
    '/films/[filmSlug]',
  );

  const trackOnSearchFilmsPage =
    checkPathnamesAreEqual(router.pathname, '/search/[searchType]') &&
    router.query?.searchType === 'films';

  const trackOnOtherPages =
    !checkPathnamesAreEqual(router.pathname, '/showing') &&
    !checkPathnameStartsWith(router.pathname, '/preview');

  const sendFilmImpressionsToSnowplow = async (filmImpression: {
    impression: FilmImpression;
    callback?: () => any;
  }) => {
    const { impression, callback } = filmImpression;

    const pageType = await getSnowplowPageType(router, vanityPathPageType);

    const sharedTrackingData = {
      film_id: impression.filmId,
      is_watchable: impression.isWatchable,
      module_type: impression.moduleType,
      page_url: router.asPath.includes('android')
        ? router.asPath.split('?')[0]
        : router.asPath,
      country_code: countryCode,
      ...pageType,
    };

    if (trackOnTodayPage) {
      trackSnowplowFilmImpression(
        {
          collection_slug: impression.collectionSlug,
          film_group_id: impression.filmGroupId,
          module_vertical_position: impression.verticalPosition,
          module_horizontal_position: impression.horizontalPosition,
          ...sharedTrackingData,
        },
        callback,
        httpContext,
      );
    } else if (trackOnSearchFilmsPage) {
      trackSnowplowFilmImpression(
        {
          module_horizontal_position: impression.searchRank,
          ...sharedTrackingData,
        },
        callback,
        httpContext,
      );
    } else if (trackOnFilmDetailsPage) {
      trackSnowplowFilmImpression(
        {
          collection_slug: impression.collectionSlug,
          ...sharedTrackingData,
        },
        callback,
        httpContext,
      );
    } else if (trackOnOtherPages) {
      trackSnowplowFilmImpression(sharedTrackingData, callback, httpContext);
    }
  };

  const doTrackSnowplowFilmImpression = async (
    impression: FilmImpression,
    callback?: () => any,
  ) => {
    sendFilmImpressionsToSnowplow({ impression, callback });
  };

  return doTrackSnowplowFilmImpression;
};

export default useSnowplowFilmImpressionTracker;
