import { useEffect } from 'react';
import styled from '@emotion/styled';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import { color as themeColors } from '@app/themes/mubi-theme';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';
import useSnowplowBannerViewTracking from '@app/hooks/snowplow/useSnowplowBannerViewTracking';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import GenericBannerContainer from '@app/components/promotional-banner/banner-containers/GenericBannerContainer';
import PromotionalBanner from '@app/components/promotional-banner/banner-containers/PromotionalBanner';

const MubiGoSaleBanner2025 = ({ cookiePrefix }: { cookiePrefix: string }) => {
  const i18nUrlData = useI18nUrlData();
  const geoLocation = useAppSelector(state => state.user.geoLocation);
  const trackSnowplowBannerView = useSnowplowBannerViewTracking();

  useEffect(() => {
    trackSnowplowBannerView({
      event_type: 'banner_shown',
      campaign: 'mubi_go_annual_jan25',
      cta: 'sign_up',
    });
  }, []);

  return (
    <GenericBannerContainer
      cookiePrefix={cookiePrefix}
      snowplowElement="mubi_go_sale_banner_2025"
    >
      {({ doBannerDismiss, doBannerClick }) => (
        <PromotionalBanner
          bgColor={themeColors.go.goldenYellow}
          fontColor={'#2E1F24'}
          wholeBannerLink
          onDismiss={doBannerDismiss}
          onPromoLinkClick={() => {
            doBannerClick(
              getFullRouteUrl({
                url: '/year_mubigo2025',
                i18nUrlData,
              }),
            );
          }}
        >
          A whole year of cinema tickets and streaming for just{' '}
          {geoLocation === 'IE' ? '129 €' : '£129'}.{' '}
          <UnderlineText>GET MUBI GO.</UnderlineText>
        </PromotionalBanner>
      )}
    </GenericBannerContainer>
  );
};

const UnderlineText = styled.span`
  text-decoration: underline;
  color: ${props => props.theme.color.go.blue};
`;

export default MubiGoSaleBanner2025;
