import { shallowEqual } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { getOnboardingOfferFooter } from '@app/services/subscribe-cta';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import Link from '@app/components/Link';
import TranslateHtml from '@app/components/TranslateHtml';

type EmailFormFooterProps = {
  isOnboardingOfferCTA?: boolean;
  textColor?: 'white' | 'black';
  ctaButtonText?: string;
  legalText?: string;
};

const EmailFormFooter = ({
  isOnboardingOfferCTA = false,
  textColor = 'white',
  ctaButtonText,
  legalText,
}: EmailFormFooterProps) => {
  const { t } = useTranslation('common');

  const { geoLocation, onboardingPromo } = useAppSelector(
    state => ({
      onboardingPromo: state.appState.onboardingConfig?.promo,
      geoLocation: state.user.geoLocation,
    }),
    shallowEqual,
  );

  let onboardingOfferFooter = null;

  if (isOnboardingOfferCTA) {
    onboardingOfferFooter = getOnboardingOfferFooter(
      t,
      onboardingPromo,
      geoLocation,
    );
  }

  return (
    <EmailFormTermsCopy color={textColor}>
      {onboardingOfferFooter}
      {legalText ? (
        legalText
      ) : (
        <TranslateHtml
          t={t}
          i18nKey="common:common.subscribe_ctas.onboarding_offer.legal_terms"
          i18nVars={{
            button_text: ctaButtonText || t('common.pitch_banner.get_started'),
            terms_link: (
              <Link
                href="/terms_of_service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('onboarding:onboarding.signup.terms_of_service')}
              </Link>
            ),
            privacy_link: (
              <Link
                href="/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('onboarding:onboarding.signup.privacy_link')}
              </Link>
            ),
          }}
        />
      )}
    </EmailFormTermsCopy>
  );
};

export default EmailFormFooter;

const EmailFormTermsCopy = styled.div<{ color: string }>`
  font-size: 12px;
  padding-top: 18px;

  color: ${props =>
    props.color === 'white' ? props.theme.color.white : 'inherited'};

  a {
    color: ${props =>
      props.color === 'white' ? props.theme.color.white : 'inherited'};
    text-decoration: underline;

    &:visited {
      color: ${props =>
        props.color === 'white' ? props.theme.color.white : 'inherited'};
    }
    &:hover {
      color: ${props =>
        props.color === 'white' ? props.theme.color.white : 'inherited'};
    }
  }
`;
