import { Dispatch, SetStateAction } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import EmailFormFooter from '@app/components/EmailFormFooter';
import TextInput from '@app/components/inputs/TextInput';
import OnboardingOfferHeading from '@app/components/onboarding/OnboardingOfferHeading';
import { SplashFormButton } from '@app/components/splash/SplashFormButton';

type EmailFormProps = {
  isOnboardingOfferCTA?: boolean;
  formTitleText: string;
  legalTextOverride?: string;
  emailAddress: string;
  setEmailAddress: Dispatch<SetStateAction<string>>;
  submitForm: () => void;
  ctaButtonText?: string;
  isDisabled?: boolean;
  textColor?: 'white' | 'black';
  className?: string;
};

const EmailForm = ({
  className,
  formTitleText,
  legalTextOverride,
  emailAddress,
  setEmailAddress,
  submitForm,
  ctaButtonText,
  textColor = 'white',
  isDisabled = false,
  isOnboardingOfferCTA = false,
}: EmailFormProps) => {
  const { t } = useTranslation('common');
  return (
    <>
      {isOnboardingOfferCTA ? (
        <OnboardingOfferHeading textColor={textColor} />
      ) : (
        <StartTrialText className={className} color={textColor}>
          {formTitleText}
        </StartTrialText>
      )}
      <StartTrialForm>
        <EmailTextInput
          value={emailAddress}
          placeholder={t('common.email_address')}
          onChange={e => {
            setEmailAddress(e.target.value);
          }}
          onKeyDown={async e => {
            if (e.key === 'Enter') {
              submitForm();
            }
          }}
        />

        <SignupButton
          noWrap
          disabled={isDisabled}
          clickAction={async () => {
            submitForm();
          }}
          isOnboardingOfferCTA={isOnboardingOfferCTA}
        >
          {ctaButtonText || t('common.pitch_banner.get_started')}
        </SignupButton>
      </StartTrialForm>
      <EmailFormFooter
        legalText={legalTextOverride}
        ctaButtonText={ctaButtonText}
        isOnboardingOfferCTA={isOnboardingOfferCTA}
        textColor={textColor}
      />
    </>
  );
};

const StartTrialText = styled.h2<{ color: string }>`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: ${props =>
    props.color === 'white' ? props.theme.color.white : 'inherited'};

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    font-size: 26px;
    margin-bottom: 12px;
  }
`;
const StartTrialForm = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    flex-direction: row;
  }
`;
const EmailTextInput = styled(TextInput)`
  border: 1px solid ${props => props.theme.color.lightGray};
  font-size: 16px;
  letter-spacing: 0;
  padding: 13px 8px;
  height: 50px;

  ::placeholder {
    font-size: 16px;
  }

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 21px 16px;
    width: 400px;
    height: auto;
  }
`;

const SignupButton = styled(SplashFormButton)`
  margin-top: 4px;
  height: 50px;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    margin-top: 0;
    height: auto;
  }
`;

export default EmailForm;
